import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL })

// SMS CRUD API's


export const getAllSmsList = (headers,pageNumber,search = "") => API.get(`/admin/getAll-smsList?page_number=${pageNumber}&search=${search}`, headers)

export const getSMSDetails = (headers,pageNumber,id,search = "",readStatus) => API.get(`/admin/get-smsDetails/${id}?page_number=${pageNumber}&search=${search}&readStatus=${readStatus}`, headers)

export const allCustomerList = (headers,search = "") => API.get(`/admin/send-sms-to-selected-users?search=${search}`, headers)

export const sendSMS = (headers,formData) => API.post(`/admin/send-sms`,formData, headers)

// export const makePoolPerksUnarchiveAPI=(headers, id) => API.post(`/admin/pool-perks-make-unarchive/${id}`,{},headers)



