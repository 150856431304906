import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Container from "../Layouts/Container";
import { Image, ImageProps, message } from "antd";
import { allCustomerList, sendSMS } from "../../API/SmsRequest";
import { sessionExpire } from "../../Scripts/Helper";
import { useState } from "react";
import { logoutAdmin } from "../../Action/authAction";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Select2 from "react-select2-wrapper";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";

const imageStyle = {
  background: "white",
};
const SendSmsForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authReducer.authData);
  const authToken = useSelector((state) => state.authReducer.token);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [currentView, setCurrentView] = useState(false);

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [poolPerkData, setPoolPerkData] = useState([]);

  const [nextPage, setNextPage] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderyBy] = useState("");
  const [activeWhatsNew, setActiveWhatsNew] = useState([]);
  const [activePostData, setActivePostData] = useState({});
  const [activePageCount, setActivePageCount] = useState(0);

  const [pageNumber, setPagenumber] = useState(1);

  const [btnLoading, setBtnLoading] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  // Custom styles for the Select component
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "0", // Remove padding
      minHeight: "38px", // Adjust as needed
      border: "1px solid #ced4da",
      borderRadius: "4px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px", // Minimal padding for content
    }),
    indicatorsContainer: () => ({
      display: "none", // This removes all indicators (dropdown arrow and clear button)
    }),
    multiValue: (provided) => ({
      ...provided,
      margin: "2px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
    }),
  };

  // const handleSelectChange = (event) => {
  //   setShowDropdown(event.target.value === "2"); // Show dropdown if "Selected Customer" is chosen
  // };

  const handleSelectChange = (event) => {
    const value = Number(event.target.value); // Convert string to number
    sendSMSForm.setFieldValue("customer_type", value); // Update Formik state
    setShowDropdown(value === 2); // Show dropdown only for "Selected Customer"
  };

  const fetchCustomers = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        time_zone: userTimeZone,
      },
    };

    try {
      const response = await allCustomerList(config, searchCustomer);
      // console.log(response.data.data.rows, "-------------------------");

      if (
        response.data.status &&
        response.data.data.rows &&
        response.data.data.rows.length > 0
      ) {
        const customerData = response.data.data.rows.map((customer) => ({
          id: customer.id,
          text: `${customer.full_name} - ${customer.phone}`, // Format correctly
        }));

        // console.log(customerData,'customerDataaaaaaaaaaaaaaaaaaaaa');

        setCustomers((prevCustomers) => {
          // Create a map of existing customers to check for duplicates
          const existingCustomersMap = new Map(
            prevCustomers.map((customer) => [customer.id, customer])
          );

          // Add new customers from the search results
          customerData.forEach((customer) => {
            existingCustomersMap.set(customer.id, customer);
          });

          // Convert back to array
          return Array.from(existingCustomersMap.values());
        });
      }
    } catch (err) {
      console.error("Error fetching customers:", err);

      if (err.code === "ERR_NETWORK") {
        toast.error("Something went wrong! Please try again later.");
      } else {
        let res = sessionExpire(err);
        toast.error(res.message);

        if (res.status) {
          dispatch(logoutAdmin());
          navigate("/login");
        }
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(searchCustomer);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchCustomer]);

  useEffect(() => {
    fetchCustomers();
  }, [debouncedSearch]);

  
  const sendSMSForm = useFormik({
    initialValues: {
      customer_type: "",
      coupon_code: "",
      message: "",
      image: null,
      selectedCustomers: [],
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      customer_type: yup.string().required("Please select type of customer"),
      coupon_code: yup.string().required("Coupon code is required"),
      message: yup.string().required("Message is required"),
      image: yup.mixed().nullable(),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(values, "valuesssssssssss");
  
      setBtnLoading(true);
  
      const formData = new FormData();
      formData.append("customer_type", values.customer_type);
      formData.append("coupon_code", values.coupon_code);
      formData.append("message", values.message);
  
      if (values.image) {
        console.log(values.image, "values.imagevalues.imagevalues.image");
        formData.append("image", values.image);
      }
  
      // ✅ Add selected customers to FormData if customer type is "Selected Customer"
      if (values.customer_type === 2) {
        values.selectedCustomers.forEach((customerId, index) => {
          formData.append(`selectedCustomers[${index}]`, customerId);
        });
      }
  
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
  
      await sendSMS(config, formData)
        .then((res) => {
          console.log(res, "resresresresresrers");
          toast.success("Form submitted successfully");
          setSelectedCustomers([])
          // ✅ Reset the form after successful submission
          resetForm();
  
        })
        .catch((err) => {
          console.log("🚀 ~ file: Profile.jsx:80 ~ err:", err);
  
          if (err.code == "ERR_NETWORK") {
            toast.error("Something went wrong! Please try again later");
          } else {
            let res = sessionExpire(err);
            toast.error(res.message);
  
            if (res.status) {
              dispatch(logoutAdmin());
              navigate("/login");
            }
          }
        });
  
      setBtnLoading(false);
    },
  });
  

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {user && (
        <Container>
          <div className="nk-content mt-5">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="row g-gs mt-3">
                    <div className="col-md-12">
                      <div className="row g-gs">
                        <form
                          className="row"
                          onSubmit={sendSMSForm.handleSubmit}
                        >
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="form-control-wrap whatNewInput">
                                <label className="form-label">
                                  Select Customer Type
                                </label>
                                <select
                                  className="form-control form-control-lg"
                                  placeholder="Select Type Of Customer"
                                  value={sendSMSForm.values.customer_type}
                                  onChange={handleSelectChange} // Calls our fixed function
                                  name="customer_type"
                                >
                                  <option value="0">Select One</option>
                                  <option value="1">All Customers</option>
                                  <option value="2">Selected Customer</option>
                                </select>
                                {sendSMSForm.values.customer_type < 1 &&
                                sendSMSForm.touched.customer_type &&
                                sendSMSForm.errors.customer_type ? (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {sendSMSForm.errors.customer_type}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {showDropdown && (
                                  <>
                                    <label className="form-label">
                                      Select Customers
                                    </label>
                                    <Select
                                      isMulti
                                      styles={customStyles}
                                      className="custom-select-container"
                                      // classNamePrefix="select"
                                      options={
                                        customers?.map((customer) => ({
                                          value: customer.id,
                                          label: customer.text,
                                        })) || []
                                      } // Ensure options are always an array
                                      value={selectedCustomers
                                        ?.map((id) => {
                                          const customer = customers?.find(
                                            (c) => c.id === id
                                          );
                                          return customer
                                            ? {
                                                value: id,
                                                label: customer.text,
                                              }
                                            : null;
                                        })
                                        .filter(Boolean)}
                                      onInputChange={(inputValue) => {
                                        setSearchCustomer(inputValue);
                                        return inputValue;
                                      }}
                                      // onChange={(selectedOptions) => {
                                      //   setSelectedCustomers(
                                      //     selectedOptions
                                      //       ? selectedOptions.map(
                                      //           (option) => option.value
                                      //         )
                                      //       : []
                                      //   );
                                      // }}
                                      onChange={(selectedOptions) => {
                                        const selectedValues = selectedOptions
                                          ? selectedOptions.map(
                                              (option) => option.value
                                            )
                                          : [];

                                        // Update both Formik's state and local state
                                        sendSMSForm.setFieldValue(
                                          "selectedCustomers",
                                          selectedValues
                                        );
                                        setSelectedCustomers(selectedValues);
                                      }}
                                      placeholder="Search by customer's name or mobile number"
                                      noOptionsMessage={() =>
                                        "No such customer found"
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-control-wrap whatNewInput">
                                <label className="form-label">
                                  Upload Image
                                </label>
                                <input
                                  type="file"
                                  className="form-control form-control-lg"
                                  accept="image/*"
                                  onChange={(e) => {
                                    if (e.target.files.length > 0) {
                                      console.log(e.target.files[0],"e.target.filese.target.filese.target.files");
                                      
                                      sendSMSForm.setFieldValue(
                                        "image",
                                        e.target.files[0]
                                      );
                                    }
                                  }}
                                />
                                {sendSMSForm.touched.image &&
                                  sendSMSForm.errors.image && (
                                    <div
                                      className="invalid-feedback"
                                      style={{ display: "block" }}
                                    >
                                      {sendSMSForm.errors.image}
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-control-wrap whatNewInput">
                                <label className="form-label">
                                  Coupon Code
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Coupon Code"
                                  name="coupon_code"
                                  {...sendSMSForm.getFieldProps("coupon_code")}
                                />
                                {sendSMSForm.touched.coupon_code &&
                                sendSMSForm.errors.coupon_code ? (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {sendSMSForm.errors.coupon_code}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="form-control-wrap whatNewInput">
                                <label className="form-label">Message</label>
                                <textarea
                                  type="text"
                                  className="form-control form-control-lg"
                                  placeholder="Write a message..."
                                  name="message"
                                  {...sendSMSForm.getFieldProps("message")}
                                />
                                {sendSMSForm.touched.message &&
                                sendSMSForm.errors.message ? (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {sendSMSForm.errors.message}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            {btnLoading ? (
                              <div className="flex justify-center items-center">
                                <Oval
                                  height="30"
                                  width="30"
                                  color="var(--ps-main)"
                                  secondaryColor="var(--ps-main)"
                                  ariaLabel="oval-loading"
                                  strokeWidth={4}
                                  strokeWidthSecondary={4}
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <button
                                type="submit px-5"
                                className="btn btn-primary d-inline w-100"
                              >
                                Submit
                              </button>
                            )}

                            {/* <button
                              type="submit px-5"
                              className="btn btn-primary d-inline w-100"
                            >
                              Submit
                            </button> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default SendSmsForm;
