import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {

    const dispatch = useDispatch()
    const currentLocation = useLocation().pathname

    const sideBar = useSelector((state) => state.authReducer.sideBar)

    const handleSideBar = () => {
        dispatch({ type: "TOGGLE_SIDEBAR" })
    }

    return (

        <>
        <div className={!sideBar ? "nk-sidebar nk-sidebar-fixed is-light is-compact" : "nk-sidebar nk-sidebar-fixed is-light nk-sidebar-active"}>
            {!sideBar
                ? <div className="nk-sidebar-element nk-sidebar-head p-0 ">
                    <div className="nk-sidebar-brand ">
                        <Link to={"/"} className="logo-link nk-sidebar-logo ">
                            <img className="" src="/images/poolstore-logo.png" style={{ width: "75px" }} alt="logo" />
                        </Link>
                    </div>
                    <div className="nk-menu-trigger me-3 ">
                        <a className="nk-nav-toggle nk-quick-nav-icon d-xl-none" onClick={e => handleSideBar()}><em className="icon ni ni-arrow-left" style={{ fontSize: "1.875rem", marginTop: "0px", marginRight: "0px" }}></em></a>
                        <a className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" onClick={e => handleSideBar()}><em className="icon ni ni-menu" style={{ fontSize: "1.875rem", marginTop: "0px", marginRight: "0px" }} /></a>
                    </div>
                </div>
                : <div className="nk-sidebar-element nk-sidebar-head ">
                    <div>
                        <h1 className="d-none">Hello</h1>
                    </div>
                    <div className="nk-sidebar-brand ">
                        <Link to={"/"} className="logo-link nk-sidebar-logo ">
                            <img className="" src="/images/poolstore-logo.png" style={{ width: "100px" }} alt="logo" />
                        </Link>
                    </div>
                    <div className="nk-menu-trigger me-n2 ">
                        <a className="nk-nav-toggle nk-quick-nav-icon d-xl-none" onClick={e => handleSideBar()}><em className="icon ni ni-arrow-left"></em></a>
                        <a className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" onClick={e => handleSideBar()}><em className="icon ni ni-menu" style={{ fontSize: "1.5rem", marginTop: "0px", marginRight: "0px" }} /></a>
                    </div>
                </div>
            }
            {/* .nk-sidebar-element */}
            <div className="nk-sidebar-element">
                <div className="nk-sidebar-content">
                    <div className="nk-sidebar-menu" data-simplebar>
                        <ul className="nk-menu">
                            <li className={currentLocation == "/" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-home-fill" /></span>
                                    <span className="nk-menu-text">Home</span>
                                </Link>
                            </li>
                            <li className={currentLocation == "/whats-new" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/whats-new"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-bag-fill" /></span>
                                    <span className="nk-menu-text">What's New</span>
                                </Link>
                            </li>
                            <li className={currentLocation == "/welcome-screen" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/welcome-screen"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-folder-fill" /></span>
                                    <span className="nk-menu-text">Welcome Screen</span>
                                </Link>
                            </li>
                            <li className={currentLocation == "/discount" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/discount"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-offer-fill"></em></span>
                                    <span className="nk-menu-text">On Sale</span>
                                </Link>
                            </li>
                            <li className={currentLocation == "/customer-database" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/customer-database"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-user-group-fill" /></span>
                                    <span className="nk-menu-text">Customers</span>
                                </Link>
                            </li>
                            {/* <li className={currentLocation == "/manage-products" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/manage-products"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-truck"></em></span>
                                    <span className="nk-menu-text">Manage Products</span>
                                </Link>
                            </li>
                            <li className={currentLocation == "/manage-orders" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/manage-orders"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-cart-fill"></em></span>
                                    <span className="nk-menu-text">Manage Orders</span>
                                </Link>
                            </li> */}
                            <li className={currentLocation == "/chat" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/chat"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><i className="fa-solid fa-wrench" style={{ fontSize: "20px" }}></i></span>
                                    <span className="nk-menu-text">Service Request</span>
                                </Link>
                            </li>
                            <li className={currentLocation == "/pool-perks" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/pool-perks"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-gift"></em></span>
                                    <span className="nk-menu-text">Pool Perks</span>
                                </Link>
                            </li>
                            <li className={currentLocation == "/sms" ? "nk-menu-item active current-page" : "nk-menu-item"}>
                                <Link to={"/sent-sms"} className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-msg-fill"></em></span>
                                    <span className="nk-menu-text">SMS</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Sidebar
