import React, { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "../Layouts/Container";
import { Image, ImageProps } from "antd";
import { allCustomerList } from "../../API/customerRequest";
import { sessionExpire } from "../../Scripts/Helper";
import { useState } from "react";
import { logoutAdmin } from "../../Action/authAction";
import { getSMSDetails } from "../../API/SmsRequest";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";

const imageStyle = {
  background: "white",
};
const SmsDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authReducer.authData);
  const authToken = useSelector((state) => state.authReducer.token);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [currentView, setCurrentView] = useState(false);

  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [poolPerkData, setPoolPerkData] = useState([]);

  const [nextPage, setNextPage] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderyBy] = useState("");
  const [activeWhatsNew, setActiveWhatsNew] = useState([]);
  const [activePostData, setActivePostData] = useState({});
  const [activePageCount, setActivePageCount] = useState(0);
  const [allSmsList, setAllSmsList] = useState([]);
  const [message, setMessage] = useState([]);
  
  const [readStatus, setReadStatus] = useState('');

  const [showSmsForm, setShowSmsForm] = useState(false);

  const [pageNumber, setPagenumber] = useState(1);

  const { id } = useParams();

  const handlePageChange = async (d) => {
    window.scrollTo(0, 0);
    var CurrentPage = d.selected + 1;
    setPagenumber(CurrentPage);
  };

  const handleSearchChange = (e) => {
    setSearchCustomer(e.target.value);
  };

  let fetchSMSDetails = async (id) => {
    setSpinnerLoading(true);

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        time_zone: userTimeZone,
      },
    };

    await getSMSDetails(config, pageNumber, id, searchCustomer,readStatus)
      .then((response) => {
        // console.log(response.data.data.rows[0].smsCustomers[0].customer, "******@@@@@@@@@@@@@@@@@@@@@@*****");

        console.log(response, "******@@@@@@@@@@@@@@@@@@@@@@*****");

        setAllSmsList(
          response
            ? response.data.data.customers
              ? response.data.data.customers
              : []
            : []
        );

        setMessage({
          message: response.data.data.message,
        });

        // setActivePostData({
        //   count: response.data.data.count,
        //   currentPage: response.data.data.currentPage,
        //   totalPages: response.data.data.totalPages,
        // });
        // setActivePageCount(Math.ceil(response.data.data.count / 10));

        // Extract message from the response
        if (response?.data?.data?.rows?.[0]?.message) {
          setMessage({
            message: response.data.data.rows[0].message,
          });
        }

        // Extract customer data from the smsCustomers array
        if (response?.data?.data?.rows?.[0]?.smsCustomers) {
          const customerList = response.data.data.rows[0].smsCustomers.map(
            (sc) => ({
              id: sc.id,
              full_name: `${sc.customer?.first_name || ""} ${
                sc.customer?.last_name || ""
              }`.trim(),
              email: sc.customer?.email || "",
              phone_code: sc.customer?.phone_code || "",
              phone: sc.customer?.phone || "",
              read_status: sc?.read_status,
            })
          );

          setAllSmsList(customerList);

          setActivePostData({
            count: response.data.data.count,
            currentPage: response.data.data.currentPage,
            totalPages: response.data.data.totalPages,
          });
          setActivePageCount(Math.ceil(response.data.data.count / 10));
        } else {
          setAllSmsList([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching get sms details:", err);

        if (err.code === "ERR_NETWORK") {
          toast.error("Something went wrong! Please try again later.");
        } else {
          let res = sessionExpire(err);
          toast.error(res.message);

          if (res.status) {
            dispatch(logoutAdmin());
            navigate("/login");
          }
        }
      });
    setSpinnerLoading(false);
  };

  useEffect(() => {
    fetchSMSDetails(id);
  }, [pageNumber, searchCustomer, readStatus]);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {user && (
        <Container>
          <div className="nk-content mt-5 ">
            <div className="container-fluid">
              <div className="nk-content-inner">
                <div className="nk-content-body">
                  <div className="nk-block-head nk-block-head-sm mt-3">
                    <div className="nk-block-between">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title page-title">
                          The Pool Store /<span> SMS Details </span>
                          {/* <br></br>
                          <br></br> */}
                          {/* Message <span> {message.message} </span> */}
                        </h4>
                      </div>
                    </div>

                    <div className="d-flex mt-3">
                      <h4 className="nk-block-title page-title">
                        Message <span> {message.message} </span>
                      </h4>

                      {/* Filter Dropdown */}
                      <div className="col-auto me-1">
                        <select
                          className="form-select form-select-sm"
                          value={readStatus}
                          onChange={(e) => setReadStatus(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="read">Read</option>
                          <option value="unread">Unread</option>
                        </select>
                      </div>

                      <div className="col-md-6 ms-auto text-end">
                        <div className="form-control-wrap searchBarTable">
                          <div className="form-icon form-icon-right">
                            <em className="icon ni ni-search"></em>
                          </div>
                          <input
                            type="text"
                            value={searchCustomer}
                            onChange={handleSearchChange}
                            className="form-control"
                            id="fv-email"
                            name="fv-email"
                            placeholder="Search Here..."
                          />
                        </div>
                      </div>
                    </div>

                    {spinnerLoading ? (
                      <div className="vh-100 flex justify-center items-center">
                        <Oval
                          height="100"
                          width="100"
                          color="var(--ps-main)"
                          secondaryColor="var(--ps-main)"
                          ariaLabel="oval-loading"
                          strokeWidth={4}
                          strokeWidthSecondary={4}
                          visible={true}
                        />
                      </div>
                    ) : (
                      <div
                        className="card card-preview "
                        // style={{ overflowX: "scroll" }}
                      >
                        <table className="table table-orders mt-3 ">
                          <thead className="tb-odr-head dashboardTableHead">
                            <tr className="tb-odr-item">
                              <th className="tb-odr-amount">
                                <span className="tb-odr-total">Name</span>
                              </th>

                              <th className="tb-odr-amount">
                                <span className="tb-odr-status">email</span>
                              </th>


                              <th className="tb-odr-amount">
                                <span className="tb-odr-total">
                                  Mobile Number
                                </span>
                              </th>

                              <th className="tb-odr-amount">
                                <span className="tb-odr-total">
                                  Read/Unread
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tb-odr-body allSmsList">
                            {allSmsList.map((data) => {
                              return (
                                <tr className="tb-odr-item" key={data.id}>
                                  <td className="tb-odr-info">
                                    {data.full_name}
                                  </td>

                                  <td className="tb-odr-info">{data.email}</td>


                                  <td className="tb-odr-info">{data.phone_code} {data.phone}</td>

                                  <td className="tb-odr-info">
                                    {data.read_status === 1 ? (
                                      <span className="badge bg-success">
                                        Read
                                      </span>
                                    ) : (
                                      <span className="badge bg-danger">
                                        Unread
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>

                        {allSmsList?.rows == 0 && (
                          <div className="d-flex justify-content-center align-item-center mt-5">
                            <span>No Data Available</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                forcePage={pageNumber - 1}
                breakLabel={"..."}
                // pageCount={10}
                pageCount={activePageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={handlePageChange}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

export default SmsDetails;
